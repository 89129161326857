<template>
  <div style="height: inherit">

    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <div class="search-results">
                Znaleziono {{ totalProducts }} kursów
              </div>
            </div>
            <div class="view-options d-flex">

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                  v-model="itemView"
                  class="ml-1 list item-view-radio-group"
                  buttons
                  size="sm"
                  button-variant="outline-primary"
              >
                <b-form-radio
                    v-for="option in itemViewOptions"
                    :key="option.value"
                    :value="option.value"
                >
                  <feather-icon
                      :icon="option.icon"
                      size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay"/>

    <!-- Searchbar -->
    <div class="custom-filters mt-1">
      <b-row>
        <b-col cols="12">
          <div class="d-inline-block mr-1">
            <b-badge pill v-bind:variant="isActiveCategory('') ? 'primary' : 'secondary'"
                     class="clickable" @click="selected_filter = ''">
              Wszystkie
            </b-badge>
          </div>
          <div v-for="category in categories" class="d-inline-block mr-1">
            <b-badge pill class="clickable"
                     v-bind:variant="isActiveCategory(category) ? 'primary' : 'secondary'"
                     @click="selected_filter = category"
            >
              {{ category }}
            </b-badge>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Products -->
    <section v-bind:class="[this.selected_groups.length === 0 ? itemView : '']">
      <div
          v-for="product in products"
          :key="product.id"
          v-show="selected_groups.length === 0 && (selected_filter === '' || selected_filter === product.category)"
      >
        <b-card
            class="ecommerce-card position-relative"
            no-body
        >
<!--          <img
              :src="freeStickerImg"
              alt="free-sticker"
              class="free-sticker"
              v-if="product.type === 'course' && product.price == 0"
          >-->
          <div class="item-img text-center">
            <b-link v-if="product.type === 'course'"
                    :to="{ name: product.subscription ? 'subscription' : 'shop-item-details', params: { slug: product.slug }}"
            >
              <b-img
                  :alt="`${product.name}-${product.id}`"
                  fluid
                  class="card-img-top"
                  :src="`${product.thumbnail}`"
              />
            </b-link>
            <b-link v-if="product.type === 'group'"
                    @click="toggleGroup(product)"
            >
              <b-img
                  v-if="product.type === 'group'"
                  :alt="`${product.name}-${product.id}`"
                  fluid
                  class="card-img-top"
                  :src="`${product.thumbnail}`"
              />
            </b-link>
          </div>

          <!-- Product Details -->
          <b-card-body>
            <div class="item-wrapper">
              <div>
                <h3 style="min-height: 90px;">{{ product.title }}</h3>
                <!-- price info -->
                <div v-if="!product.subscription">
                  <h4 class="item-price mt-1" v-if="product.type === 'course'">
                    {{ product.price.toFixed(2) }} zł
                  </h4>
                  <h6
                      v-if="product.sale_price && product.type === 'course'"
                      class="item-price-sale"
                  >
                    {{ product.sale_price.toFixed(2) }} zł
                  </h6>
                </div>
                <div v-else-if="product.subscription">
                  <h4 class="item-price mt-1" v-if="product.type === 'course'">
                    od {{ product.subscription_types[0].price.toFixed(2) }}zł
                  </h4>
                </div>
              </div>
            </div>
            <b-card-text class="">
              {{ product.short_description }}
            </b-card-text>
          </b-card-body>


          <!-- Product Actions -->
          <div class="item-options text-center">
            <div class="item-wrapper">
              <div class="item-cost">
                <h4 class="item-price" v-if="product.type === 'course' && !product.subscription">
                  {{ product.price }} zł
                </h4>
              </div>
            </div>
            <b-button
                variant="primary"
                tag="a"
                class="btn-cart"
                v-if="product.type === 'course' && !product.subscription"
                @click="clickOnAddToCart(product)"
            >
              <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
              />
              <span>{{ product.isInCart ? 'Zobacz koszyk' : 'Dodaj do koszyka' }}</span>
            </b-button>
            <b-button
                variant="primary"
                tag="a"
                class="btn-cart"
                v-if="product.type === 'group'"
                @click="toggleGroup(product)"
            >
              <feather-icon
                  icon="Maximize2Icon"
                  class="mr-50"
              />
              <span>Pokaż kursy</span>
            </b-button>
            <b-button
                variant="primary"
                tag="a"
                class="btn-cart"
                v-if="product.type === 'course' && product.subscription"
                @click="goToProduct(product)"
            >
              <span>Pokaż kurs</span>
            </b-button>
          </div>
        </b-card>
        <div v-if="product.type === 'group'">
          <div v-show="selected_groups.includes(product)">
            <b-button
                variant="primary"
                tag="a"
                @click="toggleGroup(product)"
            >
              <feather-icon
                  icon="ArrowLeftIcon"
              />
              <span>Wróć</span>
            </b-button>
          </div>
          <div class="grid-view">
            <b-card
                class="ecommerce-card position-relative"
                no-body
                v-for="child in product.children"
                :key="child.id"
                v-show="selected_groups.includes(product)"
            >
              <img
                  :src="freeStickerImg"
                  alt="free-sticker"
                  class="free-sticker"
                  v-if="child.type === 'course' && child.price == 0"
              >
              <div class="item-img text-center">
                <b-link :to="{ name: 'shop-item-details', params: { slug: child.slug }}">
                  <b-img
                      :alt="`${child.name}-${child.id}`"
                      fluid
                      class="card-img-top"
                      :src="`${child.thumbnail}`"
                  />
                </b-link>
              </div>

              <!-- Product Details -->
              <b-card-body>
                <div class="item-wrapper">
                  <div>
                    <h3>{{ child.title }}</h3>
                    <h4 class="item-price mt-1">
                      {{ child.price.toFixed(2) }} zł
                    </h4>
                    <h6
                        v-if="child.sale_price"
                        class="item-price-sale"
                    >
                      {{ child.sale_price.toFixed(2) }} zł
                    </h6>
                  </div>
                </div>
                <b-card-text class="item-description">
                  {{ child.short_description }}
                </b-card-text>
              </b-card-body>

              <!-- Product Actions -->
              <div class="item-options text-center">
                <div class="item-wrapper">
                  <div class="item-cost">
                    <h4 class="item-price">
                      {{ child.price }} zł
                    </h4>
                  </div>
                </div>
                <b-button
                    variant="primary"
                    tag="a"
                    class="btn-cart"
                    @click="handleCartActionClick(child)"
                >
                  <feather-icon
                      icon="ShoppingCartIcon"
                      class="mr-50"
                  />
                  <span>{{ child.isInCart ? 'Zobacz koszyk' : 'Dodaj do koszyka' }}</span>
                </b-button>
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
              v-model="filters.page"
              :total-rows="totalProducts"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BCollapse,
  BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {watch} from '@vue/composition-api'
import {useResponsiveAppLeftSidebarVisibility} from '@core/comp-functions/ui/app'
import ShopLeftFilterSidebar from './ShopLeftFilterSidebar.vue'
import {useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData} from './useECommerceShop'
import {useEcommerceUi} from './useEcommerce'
import {useRouter} from "@core/utils/utils";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BBadge,

    // SFC
    ShopLeftFilterSidebar,
  },
  methods: {
    toggleGroup(product) {
      if (this.selected_groups.includes(product)) {
        this.selected_groups = this.selected_groups.filter(
            (item) => item !== product
        )
      } else {
        this.selected_groups.push(product)
      }
    },
    isActiveCategory(category) {
      return this.selected_filter === category;
    },
    showAlert() {
      var timeInMs = Date.now();
      var timeToEnable = 1654524000000; // 06.06.2022 14:00 UTC

      if(timeInMs >= timeToEnable) {
        return true;
      }

      return false;
    },
    goToProduct(product) {
      this.$router.push({name: 'subscription', params: { slug: product.slug }})
    },
    expiredCheck(date) {
      if (date) {
        return new Date() <= new Date(date);
      }
      return true;
    },
    clickOnAddToCart(product) {
      this.handleCartActionClick(product)
      if (!product.isInCart) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce: {
            currency: 'PLN',
            value: product.price,
            items: [{
              item_name: product.title,
              item_id: product.id,
              price: product.subscription === 1 ? product.subscription_types[0].price : product.price,
              item_category: product.category,
              item_list_name: `Kategoria: ${product.category}`,
              item_list_id: `kategoria-${product.category}`,
              index: 1,
              quantity: 1,
            }],
          },
        })
      }
    },
  },
  created() {

  },
  computed: {
    categories() {
      if(typeof this.products === 'undefined')
      {
        return [];
      }

      return this.products.map((product) => {
        return product.category;
      }).filter((v, i, a) => a.indexOf(v) === i);
    }
  },
  setup() {
    const {
      filters,
      filterOptions,
      sortBy,
      sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const {handleCartActionClick, toggleProductInWishlist} = useEcommerceUi()

    const {
      itemView,
      itemViewOptions,
      totalProducts,
    } = useShopUi()

    const {products, fetchProducts, getCartToken} = useShopRemoteData()

    const {mqShallShowLeftSidebar} = useResponsiveAppLeftSidebarVisibility()

    const initializeCart = () => {
      getCartToken()
    }

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchProducts({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
      })
          .then(response => {
            products.value = response.data.courses
            totalProducts.value = response.data.total

            const selectedProducts = products.value
            const selectedCategory = 'Wszystkie'

            const items = selectedProducts.map((product, index) => ({
              item_name: product.title,
              item_id: product.id,
              price: product.subscription === 1 ? product.subscription_types[0].price : product.price,
              item_category: product.category,
              item_list_name: `Kategoria: ${selectedCategory}`,
              item_list_id: `kategoria-${selectedCategory}`,
              index: index + 1,
              quantity: 1,
            }))
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'view_item_list',
              ecommerce: {
                items,
              },
            })
          })
    }

    initializeCart()
    fetchShopProducts()

    watch([filters, sortBy], () => {
      fetchShopProducts()
    }, {
      deep: true,
    })

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
  data() {
    return {
      selected_groups: [],
      selected_filter: '',
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.free-sticker {
  width: 45%;
  position: absolute;
  right: -10%;
}
.clickable{
  cursor: pointer;
}
.custom-filters .badge{
  font-size: 16px;
  padding: 10px 15px;
}
@media all and (max-width: 768px) {
  .custom-filters .badge{
    font-size: 14px;
    padding: 7px 12px;
  }
}
</style>
